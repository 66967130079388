<template>
    <div  v-disable-all="!pageMeta.permissions.edit">
        <div v-if="!loading" class="rec-data-div" :class="pageMeta.component_name.toLowerCase() + '-page'">
            <form @submit.prevent="updateRecord">
                <div class="flex space-between">
                    <div class="name-id">
                        <FormClient
                            :client_id="client_id"
                            :siggys="original_signature_ids.length"
                            :form_data_id="record_id"
                            @getClient="getClient"
                        /><br />
                    </div>
                    <div class="align-right">
                        <FormCompany @getCompany="getCompany" />
                    </div>
                </div>
                <div>
                    <h2 class="margin-0">{{ pageMeta.title }}</h2>
                </div>

                <button
                    type="button"
                    @click="signature_ids = []"
                    v-if="signature_ids.length && original_signature_ids.length"
                >
                    Edit & Create Addendum
                </button>
                <button
                    v-if="original_signature_ids.length && !signature_ids.length"
                    class="align-right"
                    type="button"
                    @click="signature_ids = original_signature_ids"
                >
                    Cancel Edit
                </button>
                <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
                    You are now editing the original signed document to create an addendum
                </div>

                <hr class="m-15" />

                <!-- put generated code BELOW here -->

                <!--                <div class="dark-text header-w-bg"></div>-->

                <Dropdown
                    label="Client's Legal Condition"
                    :options="release_options"
                    v-model="rec.client_legal_condition"
                />

                <Radios
                    v-model="rec.release_type"
                    label="Release Type"
                    :options="['STANDARD RELEASE', 'FED MENTAL HEALTH TREATMENT PROGRAMS', 'FED DRUG ABUSE PROGRAMS']"
                />

                <Radios v-model="rec.under18" label="Is this client under 18 years of age?" :options="['No', 'Yes']" />

                <Input type="text" v-model="rec.program_name" label="Program Name" />
                <TextArea type="text" v-model="rec.address_of_program" label="Address of Program" />

                <TextArea type="text" v-model="rec.court_name" label="Court Name" />
                <TextArea type="text" v-model="rec.court_state" label="Court State" />
                <div class="dark-text top-30 bottom-30 font-13 bold">
                    I, {{ client.first_name }} {{ client.last_name }}, herby authorize {{ company.company_name }} and
                    the individual, program, office or entity listed below to disclose mental health treatment
                    information and records obtained in the course of my psychotherapy treatment, including, but not
                    limited to, therapist’s diagnoses, to:
                </div>
                <Dropdown label="Allow release to" :options="release_to_options" v-model="rec.release_to" />

                <TextArea
                    type="text"
                    v-model="rec.release_to_other"
                    label="Name and Address"
                    v-if="rec.release_to == 'Other'"
                />

                <p>Exceptions Include</p>
                <Checkbox v-model="rec.exception_sched" label="Information related to scheduling / appointments" />
                <Checkbox v-model="rec.exception_bills" label="Information related to billing and payments" />
                <Checkbox
                    v-model="rec.exception_treatment"
                    label="Information related to your treatment (including, but not limited to, personal materials, forms, therapy notes, etc.)"
                />

                <div class="dark-text top-30 bottom-30 font-13 bold">
                    The purpose or need for this disclosure is to assist in the coordination of services for me by
                    {{ company.company_name }}. I understand that unless otherwise specified, this information may be
                    disclosed in writing, verbally, fax or via electronic communication.
                    <br />
                    I further understand that my records are protected under the Federal rules governing the disclosure
                    of confidential patient information (42 CFS Part 2 and HIPPA) and that information about my
                    participation in treatment cannot be disclosed or re-disclosed without my written consent unless
                    otherwise provided for in the rules and regulations.
                    <br />
                    I understand that I have a right to receive a copy of this authorization. I understand that any
                    cancellation or modification of this authorization must be in writing. I understand that I have the
                    right to revoke this authorization at any time unless {{ company.company_name }} has taken action in
                    reliance upon it. And, I also understand that such revocation must be in writing and received by
                    Provider at to be effective.
                    <br />
                    I understand that {{ company.company_name }} shall not condition treatment upon my signing of this
                    authorization and that I have the right to refuse to sign this form.
                    <br />
                    I understand that information used or disclosed pursuant to this authorization may be subject to
                    re-disclosure by the recipient and may no longer be protected by the HIPAA Privacy Rule.
                    <br />
                    I understand that I may rescind this consent at anytime except to the extent that action has been
                    taken in reliance on it. I understand that this authorization will remain in effect for One (1) year
                    unless I specify an earlier expiration date below:
                </div>
                <Input type="date" v-model="rec.expiration_date" label="Date of expiration" />

                <!-- put generated code ABOVE here -->

                <div class="align-right" v-if="!signature_ids.length">
                    <button
                        v-if="original_signature_ids.length && !signature_ids.length"
                        class="align-right inline-block secondary right-15"
                        type="button"
                        @click="signature_ids = original_signature_ids"
                    >
                        Cancel Edit
                    </button>
                    <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable" />
                </div>
                <FormSignature
                    :user_id="user_id"
                    :client_id="client.id"
                    :signature_ids="signature_ids"
                    ref="form_signatures"
                    @getSignatures="getSignatures"
                    :show_witness="true"
                    v-if="signature_ids.length > 0"
                />
                <FormSignatureButton
                    :rec="rec"
                    :original_rec="original_rec"
                    :signatures_info="signatures_info"
                    :fileName="pageMeta.component_name"
                    :signature_ids="signature_ids"
                    :record_id="record_id"
                    @resetView="reloadData"
                    @fail="fail2sign"
                    @showModal="showPdfModal"
                    :save="updateRecord"
                />
            </form>
            <VIfWorkableModal :hasCloseButton="false" data-html2canvas-ignore="true" v-if="showCreatePdfModal">
                <SignConvertPdfModal />
            </VIfWorkableModal>
        </div>
    </div>
</template>
<script>
    import SignConvertPdfModal from '@/components/formpieces/SignConvertPdfModal'
    import FormClient from '@/components/formpieces/FormClient';
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import VIfWorkableModal from '@/components/general/modals/vIfWorkableModal.vue';
    import { dryalex } from '@/mixins/dryalex';
    import { merge } from 'lodash';
    import DateTime from '@/components/calendar/CreateEventModal/DateTime';

    export default {
        name: 'ReleaseInformation',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { DateTime, FormClient, FormSignature, VIfWorkableModal, FormCompany, FormSignatureButton, SignConvertPdfModal },
        data() {
            return {
                loading: 1,
                updating: 0,
                isEditable: 1,
                user_id: 0,
                client_id: 0,
                page_id: 0,
                rec: {},
                original_rec: {},
                signature_ids: [],
                original_signature_ids: [],
                showCreatePdfModal: false,
                api_signs_needed: null,
                signatures_info: null,
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id: 0,
                router_push: 0,
                release_options: [
                    { value: 'No current legal involvement', text: 'No current legal involvement' },
                    { value: 'Pretrial release', text: 'Pretrial release' },
                    { value: 'Post-trial release', text: 'Post-trial release' },
                    { value: 'Probation', text: 'Probation' },
                    { value: 'Parole', text: 'Parole' }
                ],
                release_to_options: [
                    { value: 'Court', text: 'Court' },
                    { value: 'Suncoast Safety Council', text: 'Suncoast Safety Council' },
                    { value: 'Other', text: 'Other' }
                ]
            };
        },
        methods: {
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data) {
                this.client = data;
            },
            getCompany(data) {
                this.company = data;

                if (this.record_id === '0' || this.record_id === 0) {
                    this.rec.program_name = this.company.company_name;
                    this.rec.address_of_program =
                        this.company.address +
                        ', ' +
                        this.company.city +
                        ', ' +
                        this.company.state +
                        ' ' +
                        this.company.zip;
                }
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async init() {
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                this.loading = 0;
            }
        },
        async created() {
            await this.init();
        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            }
        }
    };
</script>
